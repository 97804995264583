@import url('https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap');
@import 'style/modal';

* {
  font-family: 'Goudy Bookletter 1911', serif;
}

body {
  text-align: center;
}

  .cards {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
  }

    .card {
      position: relative;
      border: 4px solid $colour-secondary;
      border-radius: 2px;
      list-style: none;
      height: auto;
      width: 200px;
      margin: 16px;
      overflow: hidden;
      padding: 5px;  
      text-align: center;
      background-color: $colour-primary;
      transition: background-color .4s;

      &:hover {
        background-color: #F9EA72;
      }

      &:nth-child(even) {
        .card__page-number {
          left: 6px;
          right: initial;
        }    
      }
    }

    .card__link {
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;
      color: $colour-secondary;
    }

    .card__title {
      text-align: left;

      &::first-letter {
        color: red;
        font-size: 48px;
        text-transform: capitalize;
        padding-right: 4px;
        vertical-align: bottom;
        line-height: 1em;
      }
    }

    .card__img {
      border-radius: 20px;
    }

    .card__page-number {
      position: absolute;
      bottom: 0;
      right: 6px;
      font-weight: 700;
    }
