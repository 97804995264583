@import 'colours';

.modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  top: 0;
  background: $colour-primary;
  height: 100%;
  width: 100%;
  align-items: center;
}

  .modal__img {
      width: 400px;
      height: 400px;
      border-radius: 20px;
  }

  .modal__btn {
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
  }
